import { template as template_ca7e381f68004623a08d7d8fd33d8d63 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { htmlUnsafe } from '../../helpers/html-unsafe';
export default class ModulixFeedback extends Component {
    get type() {
        return this.args.answerIsValid ? 'success' : 'error';
    }
    static{
        template_ca7e381f68004623a08d7d8fd33d8d63(`
    <div class="feedback feedback--{{this.type}}">
      {{#if @feedback.state}}
        <div class="feedback__state">{{htmlUnsafe @feedback.state}}</div>
      {{/if}}
      {{htmlUnsafe @feedback.diagnosis}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
