import { template as template_e1a153ecad004dfaa3cc3e3c41a51225 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_e1a153ecad004dfaa3cc3e3c41a51225(`
  <ol class="module-objectives">
    {{#each @objectives as |objective|}}
      <li class="module-objectives__item">{{htmlUnsafe objective}}</li>
    {{/each}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
