import { template as template_37f4e098e3e640d1b691ebe7752e16a7 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_37f4e098e3e640d1b691ebe7752e16a7(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
