import { template as template_31e5e19b0a2b48858bb0ac0ce14add08 } from "@ember/template-compiler";
export default template_31e5e19b0a2b48858bb0ac0ce14add08(`
  <h1>
    {{! template-lint-disable "no-bare-strings" }}
    Page Certificat V3
  </h1>
  <p>{{@certification.fullName}} {{@certification.birthdate}}</p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
