import { template as template_b1e704b31c43403697c5e3ffe6d2a78f } from "@ember/template-compiler";
import { eq, notEq } from 'ember-truth-helpers';
export default template_b1e704b31c43403697c5e3ffe6d2a78f(`
  <div
    role="tabpanel"
    class="monpix-tabs__tabpanel"
    id="panel-{{@id}}-{{@index}}"
    tabindex={{if (eq @currentTab @index) "0" "-1"}}
    aria-labelledby="{{@id}}-{{@index}}"
    hidden={{notEq @currentTab @index}}
  >
    {{#if (eq @currentTab @index)}}
      {{yield}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
