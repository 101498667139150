import { template as template_8dfa2b033eea408094038fabce84cf01 } from "@ember/template-compiler";
export default template_8dfa2b033eea408094038fabce84cf01(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
