import { template as template_415b4dacec0a41279e7058133d477ad7 } from "@ember/template-compiler";
export default template_415b4dacec0a41279e7058133d477ad7(`
  <div class="campaign-step">
    <img class="campaign-step__image" role="presentation" src={{@step.image.src}} width="60" height="60" />
    <h3 class="campaign-step__title">{{@step.title}}</h3>
    <p class="campaign-step__description">{{@step.description}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
